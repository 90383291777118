import Vue from 'vue'
import VueRouter from 'vue-router'
import appBody from '@/components/appBody'
import appArticle from '@/components/appArticle'
import appTest from '@/components/appTest'




Vue.use(VueRouter)



export default new VueRouter({
    mode: 'history',
    props:true,
    routes:[
        {
            path: '/',
            component: appBody            
        },
        {
            name: 'about',
            path: '/about',
            component: appArticle
        },
        {
            name: 'reklama',
            path: '/reklama',
            component: () => import('@/components/appArticle')
        },
        {
            name: 'business',
            path: '/business',
            component: appArticle
        },
        {
            name: 'partners',
            path: '/partners',
            component: appArticle
        },
        {
            name: 'contact',
            path: '/contact',
            component: appArticle
        },
        {
            name: 'router',
            path: '#classic_collection',
            component: appTest
        }
    ]
})





