<template>
    <section class="content__sidebar">
        <h5 class="visually-hidden">Сайдбар</h5>
        <div class="sidebar" v-if="isOnline == true">
            <div class="sidebar__wrapper">
                <div class="sidebar__played-tracks">
                    <div class="played-tracks">
                        <header class="played-tracks__header">
                            <h3 class="played-tracks__title">Недавно играли</h3><span class="played-tracks__station-name">{{ station.name }}</span>
                        </header>
                        <ul class="played-tracks__list" role="list">
                            <appContentSidebarSong 
                                v-for="song in song_history"
                                v-bind:song="song"
                            />
                        </ul>
                    </div>
                </div>
                <div class="sidebar__next-song">
                    <div class="next-song">
                        <div class="next-song__wrapper">
                            <h3 class="next-song__title">Дальше будет</h3>
                            <div class="next-song__info">
                                <div class="next-song__content">
                                    <b class="next-song__name">{{ nextsingle.title }}</b>
                                    <i class="next-song__artist">{{ nextsingle.artist }}</i>
                                    <span class="next-song__duration">{{ nextsingle.duration | fallback }}</span>
                                </div>
                                <div class="next-song__at-time">в {{ nextsingle.played_at | hour_minute }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><button class="sidebar__close-btn" aria-label="Закрыть сайдбар">
                <div class="sidebar__close-btn-line"></div>
                <div class="sidebar__close-btn-line"></div>
            </button>
        </div>
    </section> 
</template>





<script>
import appContentSidebarSong from '@/components/appContentSidebarSong'
import {mapGetters, mapActions} from 'vuex'

export default{
    components: {
        appContentSidebarSong
    },
    data(){
        return{
            songTitle:0,
            songTime:0
        }
    },
    created(){

    },
    computed: mapGetters(['song_history', 'nextsingle', 'station', 'isOnline']),
    filters:{
            fallback: function(sec){
                         
                var min  = sec/60 ^ 0                
                var s = sec - min * 60
                s = s<10?"0"+s:s
                return min + ':' + s

            },
            hour_minute: function(mls){
                
                var milliseconds = mls * 1000 
                var dateObject = new Date(milliseconds)
                var date = dateObject.toLocaleString('en-US',{hour12:false}).split(" ")
                var time = date[1];
                var mdy = time.split(':');
                var hour = parseInt(mdy[0])
                var min  = mdy[1]                           
                return hour + ":" + min

            },
            google: function(url){
                return "https://www.google.com/search?q=" + url
            }
    }
}
</script>