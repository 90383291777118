<template>   
    <li  class="stations__card" v-bind:class="{'stations__card--playing': (st.id == station.id) && (isPlaying == true)}" v-bind:data-stream="st.id" v-bind:data-hash="st.shortcode">
        
        <div class="stations__card-box">
            <header class="stations__card-header">
                <button @click="StationClick(st.id)" class="stations__card-btn stations__card-btn--play" type="button" aria-label="Кнопка воспроизведения">
                    <svg class="stations__card-btn-icon" width="15" height="15">
                        <use xlink:href="images/sprites.svg#play-icon"></use>
                    </svg>
                </button> 
                <button @click="$emit('stopRadio')" v-bind:class="{'stations__card-btn--animation': (st.id == station.id) && (isPlaying == true)}" class="stations__card-btn stations__card-btn--pause" type="button" aria-label="Кнопка паузы">
                    <svg class="stations__card-btn-icon" width="15" height="15">
                        <use xlink:href="images/sprites.svg#pause-icon"></use>
                    </svg>
                </button>
            </header>
            <footer class="stations__card-footer">
                <div class="stations__card-icon">
                    <svg class="stations__card-icon-svg" width="30" height="30">
                        <use v-bind:xlink:href="st.shortcode|sprite"></use>
                    </svg>
                </div>
                <h3  class="stations__card-name">{{ st.name }}</h3>
                <p class="stations__card-description">{{ st.description }}</p>
            </footer>            
            <div v-bind:class="{'stations__card-status--is-online' : st.is_public}" class="stations__card-status " title="Статус станции"></div>
        </div>
        <!--
        <div class="station-offline station-offline--show">
            <div class="station-offline__wrapper">
                <div class="station-offline__icon">
                    <svg class="station-offline__icon-svg" width="50" height="50">
                        <use xlink:href="images/sprites.svg#volume-off"></use>
                    </svg>
                </div>
                <div class="station-offline__text">Сейчас станция не работает</div>
            </div>
        </div>
        
        -->
        
    </li>
</template>


<script>
import {mapGetters, mapActions} from 'vuex'

    export default{
        props:['st'],
        computed: mapGetters(['station', 'stations', 'isPlaying', 'isOnline']),
        methods:{
            StationClick(id){
                
                /*
                history.pushState(    
                    {},    
                    null,    
                    this.$route.path + '#' + encodeURIComponent(params)
                )
                */
    
               this.$store.dispatch('selectRadio', id)
            }
        },
        filters:{
            sprite: function(url){
                return 'images/sprites.svg#'+url         
            }

        }
    }
</script>