<template>    
    <li class="navigation__item"><!--<router-link 
        @click='testlink'
        :to="{name: menu_el.url, path: '/'+menu_el.url, params:{id: menu_el.id}}" 
        class="navigation__link"
        tag="a"
        >{{ menu_el.title }}</router-link>-->
        <a @click="handleClick" class="navigation__link" v-bind:data-effect="menu_el.title">{{ menu_el.title }}</a>
    </li>
</template>


<script>
import axios from 'axios'
import {mapGetters, mapActions} from 'vuex'

export default{
        props:['menu_el'],
        methods:{
            handleClick () {
                
                this.$store.dispatch('action_menu')
                //alert(this.menu_el.id)
                
                axios.get('https://radio.thebeat.uz/article.php?id=' + this.menu_el.id)
                .then(dd => ( 
                    //console.log(dd.data.text),                                       
                    this.$store.dispatch('article', dd.data)               
                ));

                
                this.$router.push({
                        name: this.menu_el.url, 
                        path: this.menu_el.url, 
                        params:{
                            id: this.menu_el.id
                        }
                })
                

                
            }
        }
    }
    /*<router-link :to="{path: '/applications/' + currentApplicationId}" class="nav-link">Overview</router-link>
    <router-link
        tag="a"
        class="navigation__link"
        :to="{path: menu_el.url, params: {alexm2: 'alexm2'} }"
    >{{ menu_el.title }}</router-link>
    */
    //<li class="navigation__item"><router-link v-bind:to="menu_el.url" class="navigation__link">{{ menu_el.title }}</router-link></li>-->
</script>


