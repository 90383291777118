<template>
    <section class="content__body">
        <h5 class="visually-hidden">Радиостанции</h5>
        <main class="body">
            <div class="body__wrapper">
                <div class="body__stations">
                    <div class="stations">
                        <h2 class="stations__header">Выберите станцию</h2>
                        <ul class="stations__cards">
                            <appStations 
                                v-for="st in stations"
                                v-if="st.is_public == true"
                                v-bind:st="st"
                                v-on:selectRadio="selectRadio"
                                v-on:stopRadio="stopRadio"
                            />
                        </ul>
                    </div>
                    <div class="stations__cards-pagination">99</div>
                </div>
                <div class="body__events">
                    <div class="events">
                        <h2 class="events__header">Программа радио</h2>
                        <ul class="events__cards">
                            <appProgramms 
                                v-for="programm in programms"
                                v-bind:programm="programm"
                            />
                        </ul>
                    </div>
                </div>
                <div class="body__markets">
                    <div class="markets">
                        <div class="markets__wrapper"><a class="markets__links markets__links--google" href="http://" target="_blank" aria-label="Play Market"></a><a class="markets__links markets__links--apple" href="http://" target="_blank" aria-label="App Store"></a></div>
                    </div>
                </div>
            </div>
        </main>
    </section>
</template>




<script>
import appStations from '@/components/appStations'
import appProgramms from '@/components/appProgramms'
import {mapGetters, mapActions} from 'vuex'


export default {
  components:{
    appStations,
    appProgramms
  },
  computed: mapGetters(['stations', 'programms', 'station', 'isPlaying', 'menu_status']),
  mounted: function(){
    //this.$store.dispatch('actStation')
    this.$store.dispatch('action_swiper')
  },
  methods:{
    selectRadio: function(id){
        //this.$emit('selectRadio', id)
        this.$store.dispatch('selectRadio', id)
    },
    stopRadio: function(){
        //this.$emit('stopRadio')
        this.$store.dispatch('actionPlay')
    }
  }  
}
</script>