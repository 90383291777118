import axios from 'axios'
//import { Swiper } from 'swiper';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
//import { Swiper, SwiperSlide } from 'swiper/vue';
//import { NchanSubscriber } from 'nchan';

export default{
    state:{
            network: true,
            status: 0,
            stations:[],
            connection: null,
            menuArray:[],
            programms:[],            
            song_history:[],
            now_playing:[],
            single:{},
            nextsingle:{},
            station:{},
            player: document.getElementById('auau'),
            //player: new Audio(),
            isPlaying: false, // Статус проигрывание плеера
            isOnline: true,   // Статус доступности радиостанции
            alexm:7,
            radio_id:0,
            caretStyle: {right:'100%'},
            progressLine: {transform:'translateX(-100%)'},
            textlink:'',
            quality: true,
            range: 10,
            article:{title:'',text:''},
            ms:0,
            asrc: '',
            menu_status: false
    },
    mutations:{
        mut_network(state, value){
            state.network = value
        },
        updateStations(state, posts){  // что меняем, новое значение
            state.stations = posts
        },
        updateStatus(state, value){
            state.status = value
        },
        updateRadioId(state, value){
            state.radio_id = value
        },
        updateMenuArray(state, value){
            state.menuArray = value
        },
        updateProgramms(state, value){
            state.programms = value
        },
        updateSongHistory(state, value){
            state.song_history = value
        },
        updateStation(state, value){
            state.station = value
        },
        updateNowPlaying(state, value){
            state.now_playing = value
        },
        updateSingle(state, value){
            state.single = value
        },
        updateNext(state, value){
            state.nextsingle = value
        },
        updateIsPlaying(state, value){            
            state.isPlaying = value
        },
        mut_ALEXM(state, value){
            state.alexm = value
        },
        mut_QUALITY(state, value){
            state.quality = value
        },
        mut_ELAPSED(state, value){
            state.single.elapsed = value
        },
        mut_REMAINING(state, value){
            state.single.remaining = value
        },
        mut_CARETSTYLE(state, value){
            state.caretStyle = value
        },
        mut_PROGRESSLINE(state, value){
            state.progressLine = value
        },
        mut_CONNECTION(state, value){
            state.connection = value
        },
        mut_RANGE(state, value){  
            //state.player.currentTime = 0 
            state.player.volume = value
            state.player.play()   
        },
        mut_ARTICLE(state, value){
            state.article = value
        },
        MS(state, value){
            state.ms = value
        },
        ISONLINE(state, value){
            state.isOnline = value
        },
        MUT_MENUSTATUS(state, value){
            state.menu_status = value
        },
        mut_srcedit(state, value){
            state.asrc = value
        }
    },
    actions:{
        ranger(ctx, vol){

            var audio = document.getElementById('auau')  
            const volume = vol / 100 // 0.5 => 50%
            //ctx.commit('mut_RANGE', volume)                      
            audio.volume = volume
            if(ctx.getters.isPlaying == true)
            {
                audio.play()  
            }
            

        },
        srcedit(ctx, src){

            var audio = document.getElementById('auau')            
            audio.src = src
            ctx.commit('mut_srcedit', ''+src+'')
            
                     

        },
        actionPlay(ctx){
            

            
            var audio = document.getElementById('auau')
    
            if(ctx.getters.isPlaying == true)
            {
                ctx.commit('updateIsPlaying', false)
                audio.pause()               
            }
            else
            {
                ctx.commit('updateIsPlaying', true)
                audio.load()
                audio.play() 
            } 
                       
                     

            
        },
        qualityPlay(ctx){
           
            //console.log( ctx.getters['station']['mounts'][1]['url'] )



            if(ctx.getters.quality == true)
            {
                ctx.commit('mut_QUALITY', false)                
                ctx.dispatch('srcedit', ''+ctx.getters['station']['mounts'][1]['url']+'')
            }
            else
            {
                ctx.commit('mut_QUALITY', true)
                ctx.dispatch('srcedit', ''+ctx.getters['station']['mounts'][0]['url']+'')
            }

            if(ctx.getters.isPlaying == true)
            {                
                var audio = document.getElementById('auau')
                audio.load()
                audio.play()   
            } 

            


        },
        radio(ctx, id){

            
            let dd  = {} 

            ctx.commit('updateStation', {id:id})
                       
              

            axios
            .get('https://stream.thebeat.uz/api/nowplaying/' + id)
            .then(nowp => {

               
                if(nowp.data.is_online == true)
                {
                                    
                    ctx.commit('ISONLINE', true),                                                                   
                    ctx.dispatch('srcedit', nowp['data']['station']['mounts'][0]['url'] )               
                    //ctx.commit('mut_srcedit', nowp['data']['station']['mounts'][0]['url']),
                    

                    ctx.commit('updateSongHistory', nowp.data.song_history.slice(0, 8) ),
                    //ctx.commit('updatePlayingNext', nowp.data.playing_next ),
                    //console.log( nowp.data.playing_next.duration ),
                    ctx.commit('updateStation', nowp.data.station ),
                    ctx.commit('updateNowPlaying', nowp.data.now_playing ),
                   
                    ctx.commit('updateSingle', {
                        title: nowp.data.now_playing.song.title, 
                        artist: nowp.data.now_playing.song.artist, 
                        duration: nowp.data.now_playing.duration, 
                        played_at: nowp.data.now_playing.played_at, 
                        elapsed: nowp.data.now_playing.elapsed, 
                        remaining: nowp.data.now_playing.remaining
                    }),
                    
                    ctx.commit('updateNext', {
                        title: nowp.data.playing_next.song.title, 
                        artist: nowp.data.playing_next.song.artist, 
                        duration: nowp.data.playing_next.duration, 
                        cued_at: nowp.data.playing_next.cued_at,
                        played_at: nowp.data.playing_next.played_at
                    }),
                    ctx.commit('updateIsPlaying', false),
                    ctx.dispatch('elapsedTimer'),
                    ctx.dispatch('progressline3'),
                    ctx.dispatch('socketConnect'),
                    ctx.commit('updateStatus', 1)
                }
                else
                {           
                    ctx.commit('ISONLINE', false),
                    ctx.commit('updateStatus', 1),
                    ctx.commit('updateMenuArray', website.data.menu),
                    ctx.commit('updateProgramms', website.data.programms)
                    ctx.dispatch('elapsedTimer')
                    ctx.dispatch('progressline3')                                     
                }
                
                
            })
            .catch(error => {
                //alert('error')
            })
           

        },
        actStation(ctx){


            Promise.all([
                axios.get('https://stream.thebeat.uz/api/stations'),
                axios.get('https://radio.thebeat.uz/json.php')
            ]).then(([
                stations,
                website
            ]) => {

                // Радиостанции
                //console.log(stations.data)
                if(stations.data.length > 0)
                {
                    ctx.commit('updateRadioId', stations['data'][0]['id'])                         
                    ctx.commit('updateStations', stations.data)
                }                
                ctx.dispatch("action_swiper")
                
                // Меню и программы
                //console.log(website.data)
                ctx.commit('updateMenuArray', website.data.menu),
                ctx.commit('updateProgramms', website.data.programms) 

                // Плеер
                if(stations.data.length > 0)
                {
                    ctx.dispatch("radio", stations['data'][0]['id'])  
                }                

            });

               
        },
        actStation33(ctx){

            
            
            
            
            
           
            //axios.get('https://radio.thebeat.uz/stations.php').then(
           axios.get('https://stream.thebeat.uz/api/stations').then(           
                
                response => {
                    
                    
                    if(response.data.length > 0)
                    {

                        
                        ctx.commit('updateRadioId', response['data'][0]['id'])                        
                        ctx.commit('updateStations', response.data)
                        ctx.dispatch("action_swiper")                
                    
                        Promise.all([
                            axios.get('https://radio.thebeat.uz/json.php'),
                            //axios.get('https://radio.thebeat.uz/nowplaying.php?id=' + response['data'][0]['id'])
                            axios.get('https://stream.thebeat.uz/api/nowplaying/' + response['data'][0]['id'])
                        ]).then(([
                            website,
                            nowp
                        ]) => {
                           
                                
                                if(nowp.data.is_online == true)
                                {
                                    
                                    
                                    ctx.commit('ISONLINE', true),                                                                   
                                    ctx.commit('updateMenuArray', website.data.menu),
                                    ctx.commit('updateProgramms', website.data.programms),

                                    ctx.commit('updateSrc', nowp['data']['station']['mounts'][0]['url']),
                                    ctx.commit('updateSrc', nowp.data.station.listen_url),
                                    ctx.commit('updateSongHistory', nowp.data.song_history.slice(0, 8) ),
                                    //ctx.commit('updatePlayingNext', nowp.data.playing_next ),
                                    //console.log( nowp.data.playing_next.duration ),
                                    ctx.commit('updateStation', nowp.data.station ),
                                    ctx.commit('updateNowPlaying', nowp.data.now_playing ),
                                    ctx.commit('updateSingle', {
                                        title: nowp.data.now_playing.song.title, 
                                        artist: nowp.data.now_playing.song.artist, 
                                        duration: nowp.data.now_playing.duration, 
                                        played_at: nowp.data.now_playing.played_at, 
                                        elapsed: nowp.data.now_playing.elapsed, 
                                        remaining: nowp.data.now_playing.remaining
                                    }),
                                    ctx.commit('updateNext', {
                                        title: nowp.data.playing_next.song.title, 
                                        artist: nowp.data.playing_next.song.artist, 
                                        duration: nowp.data.playing_next.duration, 
                                        cued_at: nowp.data.playing_next.cued_at,
                                        played_at: nowp.data.playing_next.played_at
                                    }),
                                    ctx.commit('updateIsPlaying', false),
                                    ctx.dispatch('elapsedTimer')/*,
                                    ctx.dispatch('progressline3'),
                                    ctx.dispatch('socketConnect'),*/
                                    ctx.commit('updateStatus', 1)
                                }
                                else
                                {           
                                    ctx.commit('ISONLINE', false),
                                    ctx.commit('updateStatus', 1),
                                    ctx.commit('updateMenuArray', website.data.menu),
                                    ctx.commit('updateProgramms', website.data.programms)
                                    ctx.dispatch('elapsedTimer')
                                    ctx.dispatch('progressline3')                                     
                                }
                                
                            
                              
                        });
                    } 
                               
                } 
                

            ).catch(
                error => {
                    ctx.commit('updateStatus', 0)
                }
            );

            


            
            
        },
        selectRadio(ctx, id){
            let dd  = {} 

            ctx.commit('updateStation', {id:id})
            
              

            axios
            .get('https://stream.thebeat.uz/api/nowplaying/' + id)
            .then(dd => {

                // Радиостанция доступна
                if(dd.data.is_online == true)
                {
                    ctx.commit('ISONLINE', true)

                    // Проигрывание
                    ctx.commit('updateIsPlaying', false)                
                    
                    if(ctx.getters.quality == true){
                        ctx.dispatch('srcedit', dd['data']['station']['mounts'][0]['url'] ) 
                    }
                    else
                    {
                        ctx.dispatch('srcedit', dd['data']['station']['mounts'][1]['url'] ) 
                    }              
                    ctx.dispatch('actionPlay')
                    
                    // Обновление информации
                    ctx.commit('updateStation', dd.data.station)                               
                    ctx.commit('updateSongHistory', dd.data.song_history.slice(0, 8))               
                    ctx.commit('updateNowPlaying', dd.data.now_playing)
                    ctx.commit('updateSingle', {
                        title: dd.data.now_playing.song.title, 
                        artist: dd.data.now_playing.song.artist, 
                        duration: dd.data.now_playing.duration, 
                        played_at: dd.data.now_playing.played_at, 
                        elapsed: dd.data.now_playing.elapsed, 
                        remaining: dd.data.now_playing.remaining
                    })
                    ctx.commit('updateNext', {
                        title: dd.data.playing_next.song.title, 
                        artist: dd.data.playing_next.song.artist, 
                        duration: dd.data.playing_next.duration, 
                        cued_at: dd.data.playing_next.cued_at,
                        played_at: dd.data.playing_next.played_at
                    })
                    
                    // Сокеты
                    ctx.dispatch('socketConnect')

                }
                else
                {                    
                   ctx.commit('ISONLINE', false) 
                   
                   // Проигрывание
                   ctx.commit('updateIsPlaying', false)                
                   ctx.dispatch('srcedit', '' )                
                   
                   // Обновление информации
                   ctx.commit('updateStation', dd.data.station)                               
                   ctx.commit('updateSongHistory', {})
                   ctx.commit('updateNext', {})                    
                   ctx.commit('updateNowPlaying', {})
                   ctx.commit('updateSingle', {})
                   ctx.commit('updateNextSingle', {})
                   ctx.getters.connection.close()
                   
                }               
                
                
            })
            .catch(error => {
                //alert('error')
            })
            
            
            /*
            //axios.get('https://radio.thebeat.uz/nowplaying.php?id=' + id)
            axios.get('https://stream.thebeat.uz/api/nowplaying/' + id)           
            .then(dd => (
                
                alert(6);
                alert(7);
                
                dd.data.station.is_online = dd.data.is_online,
                console.log(dd.data.station.is_online),
                ctx.commit('updateSrc', dd['data']['station']['mounts'][0]['url']),
                ctx.getters.player.play(),
                               
                ctx.commit('updateSongHistory', dd.data.song_history.slice(0, 8)),
                ctx.commit('updatePlayingNext', dd.data.playing_next),
                ctx.commit('updateStation', dd.data.station),
                ctx.commit('updateNowPlaying', dd.data.now_playing),
                ctx.commit('updateSingle', {
                    title: dd.data.now_playing.song.title, 
                    artist: dd.data.now_playing.song.artist, 
                    duration: dd.data.now_playing.duration, 
                    played_at: dd.data.now_playing.played_at, 
                    elapsed: dd.data.now_playing.elapsed, 
                    remaining: dd.data.now_playing.remaining
                    }),
                ctx.commit('updateNextSingle', {
                    title: dd.data.playing_next.song.title, 
                    artist: dd.data.playing_next.song.artist, 
                    duration: dd.data.playing_next.duration, 
                    cued_at: dd.data.playing_next.cued_at
                    }),
                ctx.commit('updateIsPlaying', true),
                //console.log(ctx.getters.player.src),
                ctx.dispatch('socketConnect') 
                             
                
            ));
            */ 
            
            
        },
        socketConnect(ctx){

                //let vm = this
                
                if(ctx.getters.connection){
                    ctx.getters.connection.close()
                }

                // Socket 
                ctx.commit('mut_CONNECTION', new WebSocket("wss://stream.thebeat.uz/api/live/nowplaying/"+ ctx.getters.station.shortcode))        
                //ctx.commit('mut_CONNECTION', new WebSocket('ws://demo.azuracast.com/api/nowplaying/1'))

                ctx.getters.connection.onopen = function(event){
                    //console.log(event)
                    //console.log("Succesfully connected to the echo Web server")
                    ctx.commit('mut_network', true)                                               
                }
                

                //console.log(ctx.getters.connection)
                ctx.getters.connection.onerror = function(error) {
                    //ctx.commit('mut_network', false) 
                    console.log('error socket') 
                };

                ctx.getters.connection.onclose = function(error) {
                    //ctx.commit('mut_network', false) 
                    
                };
                   
                ctx.getters.connection.onmessage = function(event){ 
                    
                    if(event.data)
                    {
                        ctx.commit('mut_network', true)       
                        //console.log(event)
                        let sock = JSON.parse(event.data) 
                        ctx.commit('updateSongHistory', sock.song_history.slice(0, 8))
                        ctx.commit('updateNext', sock.playing_next)
                        ctx.commit('updateStation', sock.station)

                        ctx.commit('updateSingle', {
                            title: sock.now_playing.song.title, 
                            artist: sock.now_playing.song.artist, 
                            duration: sock.now_playing.duration, 
                            played_at: sock.now_playing.played_at, 
                            elapsed: sock.now_playing.elapsed, 
                            remaining: sock.now_playing.remaining
                        })                   

                    
                        ctx.commit('updateNext', {
                            title: sock.playing_next.song.title, 
                            artist: sock.playing_next.song.artist, 
                            duration: sock.playing_next.duration, 
                            cued_at: sock.playing_next.cued_at,
                            played_at: sock.playing_next.played_at
                        })
                    }
                    else
                    {
                        ctx.commit('mut_network', false)  
                    }
                    
                                                    
                } 
                

        },
        socketConnect9(ctx){
            // https://stream.thebeat.uz/api/nowplaying/
            var sub = new NchanSubscriber('https://stream.thebeat.uz/api/live/nowplaying/classic');
            var nowPlaying;

            sub.on("message", function(message, message_metadata) {
                // Do something with the Now Playing data.
                nowPlaying = JSON.parse(message);
                console.log( nowPlaying )
            });
            sub.start();
        },
        article(ctx, value){
            ctx.commit('mut_ARTICLE', value)            
        },
        elapsedTimer(ctx){

            let x

            setInterval(() => {

                if(ctx.getters.single.elapsed < ctx.getters.single.duration)
                {
                    ctx.commit('mut_ELAPSED', (ctx.getters.single.elapsed) + 1) 
                    ctx.commit('mut_REMAINING', (ctx.getters.single.remaining) - 1)

                }
                else
                {
                    //ctx.dispatch('socketConnect')
                    //ctx.commit('mut_ELAPSED', 0)
                    //ctx.commit('mut_REMAINING', ctx.getters.single.duration)
                }

                
            }, 1000)

        },
        progressline2(ctx){
            console.log(ctx.getters.single.remaining+' - '+ctx.getters.single.duration);

            setInterval(() => {
                let x =  Number(ctx.getters.single.remaining) / (Number(ctx.getters.single.duration) / 1000) 
                ctx.commit('mut_CARETSTYLE', {right: x + '%'})
                ctx.commit('mut_PROGRESSLINE', {transform:'translateX(-' + x + '%)'})                        
            }, 10)

        },
        progressline3(ctx){
            
            //var progress = document.getElementById("progress");



            /*
            var percent = 0;           

            setInterval(() => {
              
                var duration = ctx.getters.single.duration;
                var elapsed = ctx.getters.single.elapsed;
                
                if(elapsed < duration){

                    elapsed = elapsed + 0.1;
                    ctx.commit('mut_ELAPSED', elapsed);
                    
                    var increment = 10 / duration;
                    percent = Math.min(increment * elapsed * 10, 100);
                    //progress.style.width = percent + '%'                    
                    //ctx.commit('mut_PROGRESSLINE', {transform:'translateX(-' + percent + '%)'})
                    //ctx.commit('mut_PROGRESSLINE', {width:'50%'})                    
                    console.log('---' + elapsed);
                }
                else
                {
                    percent = 0;
                }

                ctx.commit('mut_CARETSTYLE', {left: percent + '%'})
                

            }, 100);
            */

            var progress = document.getElementById("progress");
            var progressLine = document.getElementById("progressline");
            
            var percent = 0;
            if( ctx.getters.single.duration == undefined )
            {
                //alert('-')
            }
            var duration = ctx.getters.single.duration;
            var currentTime = ctx.getters.single.elapsed;

            function tes(){
                
                if(duration !== ctx.getters.single.duration){
                    duration = ctx.getters.single.duration;
                    currentTime = ctx.getters.single.elapsed;
                }

                if(currentTime < duration){

                    currentTime = currentTime + 0.1;                
                    var increment = 10 / duration;
                    percent = Math.min(increment * currentTime * 10, 100);
                    progress.style.left = percent + '%'
                    progressLine.style.width = percent + '%'
                    
                    //console.log(currentTime);
                }
            }

            setInterval(() => {
            let requestId = requestAnimationFrame(tes);                   
            }, 100);

            
            
           
            

        },
        getRandomInt(ctx) {
            ctx.commit('mut_ALEXM', Math.floor(Math.random() * 2) )
        },
        action_menu(ctx)
        {

            if(ctx.getters.menu_status == true)
            {
                ctx.commit('MUT_MENUSTATUS', false)
            }
            else
            {
                ctx.commit('MUT_MENUSTATUS', true)
            }
    
        },
        action_swiper(ctx){

            

            var windowWidth = window.innerWidth;

            

            var swiper = new Swiper(".stations", {
                Observer: true,
                lazyLoading: true,
                effect: "slide",
                grabCursor: true,
                speed: 400,
                centeredSlides: windowWidth < 550 ? true : false,
                 centeredSlidesBounds: windowWidth < 550 ? true : false,
                // hashNavigation: {
                //   watchState: true,
                //   replaceState: true
                // },
                // Swiper Main Classes
                slideClass: "stations__card",
                slideActiveClass: "stations__card-current",
                slideNextClass: "stations__card-next",
                slidePrevClass: "stations__card-prev",
                wrapperClass: "stations__cards",
                // Swiper Pagination Classes
                pagination: {
                  el: ".stations__cards-pagination",
                  modifierClass: "stations__cards-pagination-",
                  bulletActiveClass: "stations__cards-pagination-bullet--active",
                  bulletClass: "stations__cards-pagination-bullet",
                  clickableClass: "stations__cards-pagination-bullet--clickable",
                  currentClass: "stations__cards-pagination-bullet--current",
                  // dynamicBullets: true,
                  clickable: true
                },
                mousewheel: {
                  invert: true
                },
                breakpoints: {
                  270: {
                    slidesPerView: 2.5,
                    spaceBetween: 18
                  },
                  540: {
                    slidesPerView: 3.5,
                    spaceBetween: 15
                  },
                  1200: {
                    slidesPerView: 4.5
                  },
                  1400: {
                    slidesPerView: 4.5,
                    spaceBetween: 20
                  },
                  2000: {
                    slidesPerView: 4.5,
                    spaceBetween: 25
                  },
                  2800: {
                    slidesPerView: 4.5,
                    spaceBetween: 35
                  }
                }
              });

              
              
             

            

            

        },
        action_swiper_update: function(ctx){
            alert( swiper )
        }

    },      
    getters:{
        network(state){
            return state.network
        },
        stations(state){
            return state.stations
        },
        status(state){
            return state.status
        },
        menuArray(state){
            return state.menuArray
        },
        programms(state){
            return state.programms
        },
        player(state){
            return state.player
        },
        song_history(state){
            return state.song_history
        },
        station(state){
            return state.station
        },
        now_playing(state){
            return state.now_playing
        },
        single(state){
            return state.single
        },
        nextsingle(state){
            return state.nextsingle
        },
        isPlaying(state){
            return state.isPlaying
        },
        caretStyle(state){
            return state.caretStyle
        },
        progressLine(state){
            return state.progressLine
        },
        alexm(state){
            return state.alexm
        },
        quality(state){
            return state.quality
        },
        connection(state){
            return state.connection
        },
        range(state){
            return state.range
        },
        article(state){
            return state.article
        },
        ms(state){
            return state.ms
        },
        isOnline(state){
            return state.isOnline
        },
        asrc(state){
            return state.asrc
        },
        menu_status(state){
            return state.menu_status
        }
    }
}