<template>
    <li class="played-tracks__item" role="listitem">
        <div class="played-tracks__icon"><svg class="played-tracks__icon-svg" width="28" height="37">
                <use xlink:href="images/sprites.svg#song-icon"></use>
            </svg></div>
        <div class="played-tracks__info"><b class="played-tracks__song-name">{{ song.song.title  }}</b><i class="played-tracks__artist">{{ song.song.artist }}</i></div><a class="played-tracks__find-song" v-bind:href="song.song.title +' '+ song.song.artist | google" target="_blank" title="Найти песню" aria-label="Поиск песни"><svg class="played-tracks__find-song-svg" width="28" height="37">
                <use xlink:href="images/sprites.svg#light-search-icon"></use>
            </svg></a>
        <div class="played-tracks__at-time">{{ song.played_at | hour_minute }}</div>
        <div class="played-tracks__overlay">
            <div class="played-tracks__duration">{{  song.duration | fallback }}<!--3:30--></div>
        </div>
    </li>    
</template>



<script>
    export default{
        props:['song'],
        filters:{
            fallback: function(sec){
                         
                var min  = sec/60 ^ 0                
                var s = sec - min * 60
                s = s<10?"0"+s:s
                return min + ':' + s

            },
            hour_minute: function(mls){
                
                var milliseconds = mls * 1000 
                var dateObject = new Date(milliseconds)
                var date = dateObject.toLocaleString('en-US',{hour12:false}).split(" ")
                var time = date[1];
                var mdy = time.split(':');
                var hour = parseInt(mdy[0])
                var min  = mdy[1]                           
                return hour + ":" + min

            },
            google: function(url){
                return "https://www.google.com/search?q=" + url
            }
        }
    }
</script>