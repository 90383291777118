import Vue  from 'vue'
import axios from 'axios'

import Vuex from 'vuex'
import thebeat from './modules/thebeat'

Vue.use(Vuex)
//Vue.use(axios)


export default new Vuex.Store({
  modules: {  
    thebeat
  }
})
