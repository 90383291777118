<template>
    <section class="content__body">
					<h5 class="visually-hidden">Контент страницы</h5>
					<article class="page-content">
						<header class="page-content__header">
							<h1 class="page-content__title">{{ article.title }}</h1>
                            <p class="page-content__subtitle">{{ article.short }}</p>
						</header>
						<section class="page-content__text" v-html="article.text"></section>
						<section class="page-content__contacts">
							
							

							
							<a class="page-content__contacts-button"  @click="contactClick"><svg class="page-content__contacts-button-icon" width="50" height="50">
									<use xlink:href="images/sprites.svg#outer-link-icon"></use>
								</svg>Связаться с нами</a>

							<a class="page-content__contacts-button" href="/www_data/files/thebeat-mediakit.pdf"> <svg class="page-content__contacts-button-icon" width="50" height="50">
									<use xlink:href="images/sprites.svg#file-pdf"></use>
								</svg>Медиакит</a>


							
						</section>
                                <router-link
                                    tag="a"
                                    class="page-content__back-button"
                                    aria-label="Вернуться на главную"
                                    :to="{path: '/'}"
                                >
                                <svg class="page-content__back-button-icon" width="50" height="50">
								<use xlink:href="images/sprites.svg#arrow-icon"></use>
							</svg>На главную</router-link>
					</article>
	</section>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapActions} from 'vuex'

export default {
    computed: mapGetters(['article']),
	methods:{
		contactClick() {

                
                
                axios.get('https://radio.thebeat.uz/article.php?id=5')
                .then(dd => ( 
                    //console.log(dd.data)                                      
                    this.$store.dispatch('article', dd.data)               
                ));

              this.$router.push({
                        name: 'contact', 
                        path: '/contact', 
                        params:{
                            id: 5
                        }
                })  

                
        }
	}
}
</script>