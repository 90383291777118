<template>
    <section class="player">

        <div class="player_hide">

            
            <audio id="auau" src="" controls crossorigin ></audio> 
            <p>{{ asrc }}</p>           
            <!--<p> 
                Ссылка: {{ asrc }}<br>
                Станция: {{ isOnline }}<br>
                Композиция: {{ isPlaying }}              
            </p>
            -->

            <p>
            {{ single.elapsed | fallback }} = {{ single.remaining | fallback }} = {{ single.duration | fallback }}
            </p>
        </div>
        
        
        
        
        <div class="player__wrapper" v-if="isOnline == true">
            <div class="player__track">
                <div class="player__status" v-bind:class="{ 'player__status--active': isPlaying }">
                    <h5 class="visually-hidden">Статус воспроизведения</h5>
                </div>
                <div class="player__track-info">
                    <div class="player__track-name">{{ single.title }}</div>
                    <div class="player__track-artist">{{ single.artist }}</div>
                </div>
            </div>
            <div @click="action_play" class="player__buttons"><button class="player__buttons-btn player__buttons-btn--play" v-bind:class="{ 'player__buttons-btn--active': isPlaying }" type="button" aria-label="Кнопка воспроизведения"><svg class="player__buttons-icon" width="28" height="28">
                        <use xlink:href="images/sprites.svg#play-icon"></use>
                    </svg></button> <button class="player__buttons-btn player__buttons-btn--pause" type="button" aria-label="Кнопка остановки"><svg class="player__buttons-icon" width="28" height="28">
                        <use xlink:href="images/sprites.svg#pause-icon"></use>
                    </svg></button></div>
            <div class="player__progress">
                <div class="player__progress-time player__progress-time--played" data-played-time="42" data-duration="320">{{ single.elapsed | fallback }}</div>
                <div class="player__progress-wrapper">
                    <div class="player__progress-bar">
                        <div class="player__progress-muted"></div>
                        <div class="player__progress-line" id="progressline"></div>
                    </div>
                    <div class="player__progress-caret" id="progress" style="transition: width .1s linear"></div>
                </div>
                <div class="player__progress-time player__progress-time--total" data-duration="320">{{ single.remaining | fallback }}</div>
            </div>
            <div class="player__radio-name">{{ station.name }}</div>
            <div class="player__controls"><a class="player__controls-btn player__controls-btn--hq" v-bind:href="single.title + ' ' + single.artist | google " target="_blank" type="button" aria-label="Поиск трека" data-tooltip="Поиск трека"><svg class="player__controls-icon player__controls-icon--search" width="30" height="30">
                        <use xlink:href="images/sprites.svg#search-icon"></use>
                    </svg></a><button v-on:click="qualityPlay" class="player__controls-btn player__controls-btn--hq" v-bind:class="{ 'player__controls-btn--active': quality }" type="button" aria-label="Качество потока" data-tooltip="Качество"><svg class="player__controls-icon player__controls-icon--hq" width="33" height="33">
                        <use xlink:href="images/sprites.svg#hq-icon"></use>
                    </svg></button> <button class="player__controls-btn player__controls-btn--volume" type="button" aria-label="Громкость" data-tooltip=""><svg class="player__controls-icon player__controls-icon--volume" width="30" height="30">
                        <use xlink:href="images/sprites.svg#volume-icon"></use>
                    </svg>

                    

                    <div class="player__volume">
                        <div class="player__volume-wrapper">
                            <input 
                                class="player__volume-control" 
                                type="range" 
                                name="volume"
                                min="0" 
                                max="100" 
                                step="1" 
                                v-model.number="rang"
                                @change="rangeEdit"
                            >
                        </div>
                    </div>

                </button></div>
        </div>  

         

    </section>
</template>
    
    
    
<script>
import {mapGetters, mapActions} from 'vuex'
import AudioMotionAnalyzer from 'audiomotion-analyzer'
//import AudioMotionAnalyzer from '/public/js/audioMotion-analyzer.js';

export default{
    data(){
        return{
            rang: 100
        }
    },
    methods:{
        rangeEdit: function(){
            this.$store.dispatch('ranger', this.rang)
        },
        action_play: function(){ 
            this.$store.dispatch('actionPlay')            
        },
        qualityPlay: function(){
            this.$store.dispatch('qualityPlay')   
        }     
    },       
    computed: mapGetters(['srcedit', 'asrc', 'single', 'station', 'player', 'isPlaying', 'isOnline', 'single', 'nextsingle', 'caretStyle', 'progressLine', 'range', 'quality']),
    filters: {
            google: function(url){
                url = url.replace(/<(.|\n)*?>/g, '')
                return "https://www.google.com/search?q=" + url
            },
            fallback: function(sec){
                         
                var min  = sec/60 ^ 0                
                var s = sec - min * 60
                s = s<10?"0"+s:s
                return min + ':' + s
         
            }
    },
    mounted(){

        this.$store.dispatch('actStation')
        
        /*
        const options = {
            bgColor: '#FBFAFB',
            dir: 'v',
            colorStops: ['hsl(0, 0%, 95%)', 'hsl(0, 0%, 85%)', 'hsl(0, 0%, 75%)']
        }
        */
        

        const audioMotion = new AudioMotionAnalyzer(
                document.querySelector(".topbar__equilizer"),
                //document.getElementById("tv2"),
                {
                    source: document.getElementById("auau"),
                    overlay: true,
                    showBgColor: false,
                    showScaleX: false,
                    mode: 1,
                    gradient: 'thebeatLight',
                    barSpace: 0.5,
                    showPeaks: false,
                    ledBars: true,
                    minFreq: 20,
                    maxFreq: 22000,
                    bgAlpha: 0                   
                }
        )
        
        //audioMotion.registerGradient('thebeatLight', options )
        

    }
    
}
</script>


<style scoped>
.player_hide{
    position:fixed;
    left:-100px;
    bottom:-100px;
    z-index:-999;
    background-color:#fff;
    padding:10px;
    opacity:0;
    height:1px;
    width:1px;
    overflow: hidden;
}
.player__progress-caret{
     transition: width .1s linear;
     margin-left:-2px;   
}
.player__progress-line{
        right:auto;
        left:0px;
        width:0px;
        -webkit-transform: none;
        transform: none;
}

</style>
    
    
