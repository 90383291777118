import Vue from 'vue'
//import axios from 'axios'
//import VueAxios from 'vue-axios'
//Vue.use(axios)
import App from './App.vue'
//import App from './admin.vue'
import router from './router'
import store  from './store'





Vue.config.productionTip = false

new Vue({
  router, store,
  render: function (h) { return h(App) }
}).$mount('#app')
