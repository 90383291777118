<template>
    <li class="events__card">
        <div class="events__card-data">
            <h3 class="events__card-title">{{ programm.title }}</h3><p class="events__card-description">{{ programm.description }}</p><time class="events__card-date" v-bind:datetime="programm.date">{{ programm.datef }}</time>
        </div>
        <picture class="events__card-image"><img v-bind:src="programm.images" v-bind:alt="programm.title"></picture>
    </li>
</template>



<script>
export default{
    props:['programm']
}
</script>