<template>
    <section class="content__topbar">
        <h5 class="visually-hidden">Топбар</h5>
        <div class="topbar">
            <div class="topbar__wrapper">
                
                <div class="topbar__logo" href="" @click="menu_logo">
                <svg class="topbar__logo-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.65 81.9" width="168" height="82">
                    <path d="M.23,57V1.46A1.47,1.47,0,0,1,1.69,0H2.76A1.46,1.46,0,0,1,4.21,1.46h0V27q6.68-10.17,17-10.17a18.62,18.62,0,0,1,7.32,1.5,21.24,21.24,0,0,1,6.41,4.18,19.73,19.73,0,0,1,4.57,6.82,24.4,24.4,0,0,1,0,18.06A19.81,19.81,0,0,1,34.9,54.2a21.18,21.18,0,0,1-6.41,4.22,18.42,18.42,0,0,1-7.32,1.52q-10.49,0-17-9.86v7A1.93,1.93,0,0,1,2.28,59H2.16A1.94,1.94,0,0,1,.23,57ZM4,38.45A17.8,17.8,0,0,0,6.4,47.66,16.92,16.92,0,0,0,12.65,54a16.15,16.15,0,0,0,8.13,2.17,15.35,15.35,0,0,0,11.54-4.77q4.59-4.77,4.6-13a20.33,20.33,0,0,0-1.3-7.32,16.53,16.53,0,0,0-3.51-5.64A16.2,16.2,0,0,0,27,21.87a15.44,15.44,0,0,0-12.4,0,18,18,0,0,0-5.39,3.61A16.92,16.92,0,0,0,5.41,31.2,18.42,18.42,0,0,0,4,38.45Z" transform="translate(0.01)" />
                    <path d="M59.53,54.6a1.22,1.22,0,0,1-1.69.31l-.07,0a12.09,12.09,0,0,1-1-.85,18.87,18.87,0,0,1-4.4-6.86,24.4,24.4,0,0,1-.14-17.23,21.69,21.69,0,0,1,4.06-6.85,18.93,18.93,0,0,1,6.21-4.57,18.2,18.2,0,0,1,7.75-1.7,17.81,17.81,0,0,1,10,2.86,18.39,18.39,0,0,1,6.65,7.75,25.55,25.55,0,0,1,2.28,11A14.63,14.63,0,0,1,89.09,40H54.89A17,17,0,0,0,60.1,51.71h0a1.22,1.22,0,0,1,.28,1.67ZM54.89,36.44h30a21.63,21.63,0,0,0-1.22-6,18.59,18.59,0,0,0-2.8-5.09,12.48,12.48,0,0,0-4.56-3.59,14.33,14.33,0,0,0-6.21-1.32A13.62,13.62,0,0,0,59.73,25a18.42,18.42,0,0,0-4.84,11.44Z" transform="translate(0.01)" />
                    <path d="M149.23,21.42V17.79h0V6.59a2,2,0,0,1,2-2h0a2,2,0,0,1,2,2v11.2h12.77a1.71,1.71,0,0,1,1.71,1.71h0v.21a1.72,1.72,0,0,1-1.71,1.71H153.18V48q0,8,8.24,8a13.24,13.24,0,0,0,4.18-.71,1.45,1.45,0,0,1,1.9,1.36v.7a1.42,1.42,0,0,1-.91,1.33,15.74,15.74,0,0,1-5.8,1,12.12,12.12,0,0,1-8.34-2.84q-3.22-2.84-3.22-8.44v-27Z" transform="translate(0.01)" />
                    <path d="M153.6,81.9a6.06,6.06,0,0,1-.73,0,8,8,0,0,1-6.6-4.66l-25.33-54a4.22,4.22,0,0,0-7.65-.09L95.56,58.84a8,8,0,0,1-13.87,1l-1-1.46a4.21,4.21,0,0,0-3.5-1.86h0a4.2,4.2,0,0,0-3.5,1.92L64.09,72.86A8,8,0,0,1,51,73.37L46.84,68a4.23,4.23,0,0,0-3.34-1.64h0A4.19,4.19,0,0,0,40.14,68a8,8,0,0,1-6.38,3.16L1.92,71a2,2,0,0,1,0-3.94l31.84.16a4.22,4.22,0,0,0,3.36-1.67,8,8,0,0,1,6.35-3.17h0a8,8,0,0,1,6.34,3.13L54,70.91a4.24,4.24,0,0,0,5.94.75,4.57,4.57,0,0,0,.94-1l9.57-14.42a8.09,8.09,0,0,1,6.65-3.64h.06a8.06,8.06,0,0,1,6.64,3.53l1,1.46a4.22,4.22,0,0,0,3.8,1.84,4.3,4.3,0,0,0,3.5-2.36l17.73-35.7a8,8,0,0,1,14.55.17l25.32,54a4.23,4.23,0,0,0,5.61,2.07,4.17,4.17,0,0,0,1.71-1.43L164.15,66a1.9,1.9,0,0,1,2.63-.5l.06,0a2,2,0,0,1,.44,2.76l-7.1,10.17A8,8,0,0,1,153.6,81.9Z" transform="translate(0.01)" />
                    <path d="M8.21,7.39V3.88H7.36V2.56h.85V.72H9.74V2.56h1.8V3.87H9.74V7.15a.74.74,0,0,0,.61.84h.21a2.08,2.08,0,0,0,.95-.23V9a2.57,2.57,0,0,1-1.38.34A1.71,1.71,0,0,1,8.2,7.88,1.62,1.62,0,0,1,8.21,7.39Z" transform="translate(0.01)" />
                    <path d="M13.08,0h1.53V3.6A2.41,2.41,0,0,1,16.7,2.42a2.32,2.32,0,0,1,2.4,2.2,2.23,2.23,0,0,1,0,.37V9.25H17.55V5.45c0-1-.52-1.64-1.43-1.64A1.5,1.5,0,0,0,14.6,5.27c0,.07,0,.13,0,.2V9.24H13.08Z" transform="translate(0.01)" />
                    <path d="M20.57,5.9a3.32,3.32,0,0,1,3.15-3.48h.11C26,2.42,27,4.09,27,6c0,.14,0,.29,0,.43H22.09A1.88,1.88,0,0,0,24,8.15a2.44,2.44,0,0,0,1.81-.8l.9.8a3.5,3.5,0,0,1-6.17-2A2.09,2.09,0,0,1,20.57,5.9Zm4.94-.47a1.73,1.73,0,0,0-1.7-1.76,1.81,1.81,0,0,0-1.73,1.76Z" transform="translate(0.01)" />
                </svg></div>

                <div class="topbar__equilizer">
                    <div class="topbar__equilizer-bg" v-bind:class="{ 'topbar__equilizer-bg--hidden': isPlaying }"></div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    computed: mapGetters(['isPlaying']),
    methods: {
        menu_logo: function(){ 

            
            this.$router.push({
                path: '/'
            })

          

            this.$store.dispatch('action_swiper')
            
        }
    }
}
</script>