<template>
  <section class="app" id="app"> 
    
    

    <section class="content" v-if="network == true" >
        <h5 class="visually-hidden">Контент The Beat</h5>
        <appContentMenu /> 
        <appTopbar />        
        <router-view /> 
        <appContentSidebar />  
    </section>    
    <appPlayer v-if="network == true"/>
    <appPreloader v-if="status == 0 && network == true" />    
    <appResize v-if="network == true" />
    <appIntal v-if="network == true" />
    

    
    <div class="station-offline station-offline--show" v-if="network == true" v-bind:class="{ 'station-offline--hide': isOnline }">
        <div class="station-offline__wrapper">
            <div class="station-offline__icon">
                <svg class="station-offline__icon-svg" width="50" height="50">
                    <use xlink:href="images/sprites.svg#volume-off"></use>
                </svg>
            </div>
            <div class="station-offline__text">Сейчас станция не работает</div>
        </div>
    </div>

    
    
    
    <appOffline v-if="network == false" />    	
  </section>
</template>

<script>
import appContentMenu  from '@/components/appContentMenu'
import appTopbar  from '@/components/appTopbar'
import appBody  from '@/components/appBody'
import appContentSidebar  from '@/components/appContentSidebar'
import appPlayer    from '@/components/appPlayer'
import appPreloader from '@/components/appPreloader'
import appResize    from '@/components/appResize'
import appIntal     from '@/components/appIntal'
import appOffline   from '@/components/appOffline'






/*
import {mapGetters, mapActions} from 'vuex'

export default{
    name: 'app',
    components:{
        appContent, 
        appPlayer,
        appPreloader,
        appResize,
        appIntal,
        appOffline
    },
    methods: mapActions(['actStation']),
    async mounted(){
      this.actStation();
    },
    computed: mapGetters(['single', 'station', 'player', 'isPlaying', 'single', 'nextsingle', 'caretStyle', 'progressLine'])  
    
}
*/

import {mapGetters, mapActions} from 'vuex'


export default {
  components:{
    appContentMenu,
    appTopbar,
    appBody,
    appContentSidebar,
    appPlayer,
    appPreloader,
    appResize,
    appIntal,
    appOffline 
  },
  mounted(){
    
   // this.$store.dispatch('actStation')

  },
  computed: mapGetters(['network', 'status', 'isOnline'])
}
</script>
